import Glide, { Autoplay } from '@glidejs/glide';
import Walnut from './walnut/walnut';

document.querySelectorAll('.walnut').forEach((el) => {
  new Walnut([...el.getElementsByTagName('img'), ...el.querySelectorAll('.image')]);
});

// Initiates image slider
const slides = document.querySelectorAll('.glide');
if (slides.length > 0) {
  const glide = new Glide('.glide', {
    autoplay: 5000,
  }).mount();
}

// Opens mobile menu
const menuIcon = document.querySelector('.js-mobileMenuIcon');
menuIcon.addEventListener('click', openMobileNav);

function openMobileNav() {
  const siteMenu = document.querySelector('.siteHeader');
  siteMenu.classList.toggle('siteHeader-mobileActive');
}


// Toggles Menu items that has children in mobile mode
const menuItemWithChildren = document.querySelectorAll('.menu-item-has-children > a');
for (let i = 0; i < menuItemWithChildren.length; i++) {
  menuItemWithChildren[i].addEventListener('click', toggleMenuItemWithChildren);
}

function toggleMenuItemWithChildren(e) {
  const mobileState = document.querySelector('.siteHeader-mobileActive') ? true : false;
  if (mobileState === true) {
    e.preventDefault();
    const isOpen = this.parentElement.classList.contains('is-mobileActive');

    if (isOpen === false) {
      this.parentElement.classList.toggle('is-mobileActive');
      this.removeEventListener('click', toggleMenuItemWithChildren);
    } else {
      window.location = this.href;
    }
  }
}


// Product boxes
const boxes = document.querySelectorAll('.boxItem');
for (let i = 0; i < boxes.length; i++) {
  boxes[i].addEventListener('click', expandBox);
}

function expandBox() {
  const boxes = document.querySelectorAll('.boxItem.is-active');
  for (let i = 0; i < boxes.length; i++) {
    boxes[i].classList.remove('is-active');
    if(boxes[i] === this) {
      return;
    }
  }
  this.classList.toggle('is-active');
}

// Front page boxes
const items = document.querySelectorAll('.homeCat_item');
for (let i = 0; i < items.length; i++) {
  items[i].addEventListener('click', expandItem);
}

function expandItem() {
  const items = document.querySelectorAll('.homeCat_item.is-active');
  for (let i = 0; i < items.length; i++) {
    items[i].classList.remove('is-active');
    if(items[i] === this) {
      return;
    }
  }
  this.classList.toggle('is-active');
}
